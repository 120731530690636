import ky from "ky";
import Repository from "./Repository";

const version = process.env.VUE_APP_API_VERSION
const resource = "file";
export default {
  uploadFile(url, options) {
    return ky.put(url, options);
  },

  fetchSlideImageFile(payload) {
    return Repository.post(`${version}/${resource}/fetch_slide_image`, { json: payload });
  },

  getUploadUrl(payload) {
    return Repository.post(`${version}/${resource}/get_upload_url`, { json: payload });
  },

  getPptxUploadUrl(payload) {
    return Repository.post(`${version}/${resource}/get_pptx_upload_url`, { json: payload });
  },

  getDownloadUrl(payload) {
    return Repository.post(`${version}/${resource}/get_download_url`, { json: payload });
  },

  archivePackage(payload) {
    return Repository.post(`${version}/${resource}/archive_package`, { json: payload });
  },

  deletePackage(payload) {
    return Repository.post(`${version}/${resource}/delete_package`, { json: payload });
  },

  deleteSlide(payload) {
    return Repository.post(`${version}/${resource}/delete_slide`, { json: payload });
  },

  eliminatePackage(payload) {
    return Repository.post(`${version}/${resource}/eliminate_package`, { json: payload });
  },

  eliminateSlide(payload) {
    return Repository.post(`${version}/${resource}/eliminate_slide`, { json: payload });
  },

  likePackage(payload) {
    return Repository.post(`${version}/${resource}/like_package`, { json: payload });
  },

  likeSlide(payload) {
    return Repository.post(`${version}/${resource}/like_slide`, { json: payload });
  },

  unlikePackage(payload) {
    return Repository.post(`${version}/${resource}/unlike_package`, { json: payload });
  },

  unlikeSlide(payload) {
    return Repository.post(`${version}/${resource}/unlike_slide`, { json: payload });
  },

  getSlideInfo(payload) {
    return Repository.post(`${version}/${resource}/get_slide_info`, { json: payload });
  },

  getPackageInfo(payload) {
    return Repository.post(`${version}/${resource}/get_package_info`, { json: payload });
  },

  incrementPageview(payload) {
    return Repository.post(`${version}/${resource}/increment_pageview`, { json: payload });
  },

  updatePackage(payload) {
    return Repository.post(`${version}/${resource}/update_package`, { json: payload });
  },

  updatePackageHeader(payload) {
    return Repository.put(`${version}/${resource}/update_package_header`, { json: payload });
  },

  createComment(payload) {
    return Repository.post(`${version}/${resource}/create_comment`, { json: payload });
  },

  editComment(payload) {
    return Repository.put(`${version}/${resource}/edit_comment`, { json: payload });
  },

  deleteComment(payload) {
    return Repository.post(`${version}/${resource}/delete_comment`, { json: payload });
  },
};