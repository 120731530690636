<template>
  <div
    class="p-6 overflow-y-auto rounded shadow-2xl bg-white"
    style="width: 560px;"
  >
    <div class="mb-3 title">
      <h1 class="font-bold mb-1">ファイルアップロード</h1>
      <p class="text-sm text-gray-400">
        ファイルフォーマットは「PPTX,PDF」のみに対応しています。
      </p>
    </div>
    <div class="content">
      <file-upload-form 
        @before-upload="beforeUpload"
        @after-upload="afterUpload"
      />
    </div>
    <loading
      v-model:active="state.loading"
      color="#818589"
      blur=""
    />
  </div>
  <file-upload-success-modal
    :show-modal="state.showModal"
    @close-modal="state.showModal = false"
  />
</template>

<script>
import { reactive } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import FileUploadSuccessModal from "./modals/FileUploadSuccessModal.vue";
import FileUploadForm from "./FileUploadeForm.vue"


export default {
  components: {
    FileUploadSuccessModal,
    FileUploadForm,
    Loading,
  },
  setup() {
    const state = reactive({
      showModal: false,
      loading: false,
    });

    const beforeUpload = () => {
      state.loading = true;
    }

    const afterUpload = () => {
      state.loading = false;
      state.showModal = true;
    }

    return {
      state,
      beforeUpload,
      afterUpload
    };
  }
};
</script>