<template>
  <div
    v-for="(file, index) in files"
    :key="file.pin_id"
    class="bg-white rounded shadow-md"
    :class="{ sort: sortable }"
    :draggable="sortable ? 'false' : 'true'"
    @dragstart="onDragStartFromBoard($event, file)"
    @dragend="onDragEnd"
  >
    <div
      @click.prevent="$emit('openPreviewModal', file)"
      class="cursor-pointer"
    >
      <div
        @mouseover="onMouseOver(index)"
        @mouseleave="onMouseLeave"
        class="relative overflow-hidden rounded-t bg-gray-50 preview"
      >
        <div
          v-bind:style="{
            backgroundImage:
              'slideInfo' in file
                ? 'url(' + state.baseUrl + file.slideInfo.id + '.jpg)'
                : 'url(' + state.baseUrl + file.packageInfo.headerId + '.jpg)'
          }"
          class="absolute inset-0 bg-center bg-no-repeat bg-cover preview-background aspect-w-16 aspect-h-9 filter blur-xs contrast-50"
        ></div>
        <div
          v-bind:style="{
            backgroundImage:
              'slideInfo' in file
                ? 'url(' + state.baseUrl + file.slideInfo.id + '.jpg)'
                : 'url(' + state.baseUrl + file.packageInfo.headerId + '.jpg)'
          }"
          class="bg-center bg-no-repeat bg-contain preview-thumbnail aspect-w-16 aspect-h-9"
        ></div>
        <div
          class="flex justify-end absolute inset-0 top-0 w-full p-2 hover:bg-black hover:bg-opacity-30"
        >
          <div
            v-show="isNotBoardViwer && state.hoverFlag && index === state.hoverIndex"
          >
            <button
              v-on:click.stop="$emit('togglePinModal', file)"
              class="absolute outline-none bottom-2 right-2 focus:outline-none"
            >
              <div class="p-2 bg-white border rounded-full hover:bg-gray-50">
                <round-edit class="w-5 h-5" />
              </div>
            </button>
            <button
              v-if="isLikedPackage(file.packageInfo)"
              @click.stop="unlikePackage(file.packageInfo)"
              class="px-2 text-sm text-white transition-all duration-150 ease-linear rounded-full outline-none focus:outline-none"
            >
              <liked-icon class="text-3xl" />
            </button>
            <button
              v-else
              @click.stop="likePackage(file.packageInfo)"
              class="px-2 text-sm text-white transition-all duration-150 ease-linear rounded-full outline-none bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 focus:outline-none"
            >
              <unlike-icon class="text-3xl" />
            </button>
          </div>
        </div>
        <div
          class="absolute outline-none top-3 left-3 filter drop-shadow-xl focus:outline-none"
          v-show="file.packageInfo.permission === 'limited'"
        >
          <lock-icon class="w-5 h-5 text-yellow-500 stroke-current stroke-2" />
        </div>
      </div>
    </div>

    <div class="p-2 text-gray-600 meta">
      <div class="mb-1 meta-top">
        <p class="text-sm truncate">
          {{
            "slideInfo" in file
              ? file.slideInfo.name
              : file.packageInfo.fileTitle
          }}
        </p>
      </div>
      <div
        class="flex items-center justify-between mb-1.5 text-gray-400 meta-buttom"
      >
        <router-link
          class="flex items-center meta-user"
          :to="{
            name: 'user',
            path: `/users/${file.packageInfo?.user?.username}/profile`,
            params: { username: file.packageInfo?.user?.username, workspace: file.packageInfo?.user?.workSpace, id: file.packageInfo?.user?.id }
          }"
        >
          <img
            v-if="file.packageInfo.user.photoURL"
            :src="file.packageInfo.user.photoURL"
            class="object-cover w-5 h-5 border rounded-full"
          />
          <div
            v-else
            class="object-cover w-5 h-5 border rounded-full"
          ></div>

          <p class="ml-2 text-xs truncate hover:underline">
            {{
              file.packageInfo.user.displayName
                ? file.packageInfo.user.displayName
                : `${file.packageInfo.user.lastName} ${file.packageInfo.user.firstName}`
            }}
          </p>
          <p class="ml-2 text-xs truncate">
            {{ formatTime(file.packageInfo.created_timestamp) }}
          </p>
        </router-link>
        <div class="flex items-center">
          <liked-icon
            v-if="isLikedPackage(file.packageInfo)"
            class="w-4 h-4 ml-2"
          />
          <like-count-icon
            v-else
            class="w-4 h-4 ml-2"
          />
          <span
            class="ml-1 text-xs"
            :class="isLikedPackage(file.packageInfo) ? 'text-red-400' : ''"
          >
            {{ file.packageInfo.liked_users?.length }}
          </span>
          <eye-icon class="w-4 h-4 ml-2" />
          <span class="ml-1 text-xs">{{ file.packageInfo.pageview }}</span>
        </div>
      </div>
      <div class="flex items-center mb-0.5">
        <div
          v-if="state.addPinFlag && state.selectedPin == file.packageInfo"
        >
          <button
            v-if="boards.length > 0 && hasAddPinPermission(file.packageInfo)"
            @click.stop="toggleOperationModal(file.packageInfo)"
            class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
            type="button"
          >
            <cancel-add-pin-icon />
          </button>
        </div>
        <tooltip-text
          v-else
          text="ボードに追加"
          placement="bottom"
        >
          <button
            v-if="boards.length > 0 && hasAddPinPermission(file.packageInfo)"
            @click.stop="toggleOperationModal(file.packageInfo)"
            class="flex mr-1.5 border border-gray-400 rounded transition hover:text-white hover:border-blue-400 hover:bg-blue-400 focus:outline-none "
            type="button"
          >
            <plus class="w-4 h-4 p-0.5" />
          </button>
        </tooltip-text>
        <tooltip-text text="他人の非公開資料はボードに追加できません" placement="right">
          <button
            v-if="!(boards.length > 0 && hasAddPinPermission(file.packageInfo))"
            class="flex mr-1.5 border border-gray-300 rounded cursor-not-allowed "
          >
            <plus class="w-4 h-4 p-0.5" />
          </button>
        </tooltip-text>
        <div
          v-if="state.addPinFlag && state.selectedPin == file.packageInfo"
          class="flex flex-row"
        >
          <div class="h-5 mr-1">
            <input
              id="search-form"
              class="flex justify-center items-center w-44 h-full pl-2 text-xs border border-gray-400 bg-gray-100 rounded-lg focus:outline-none"
              type="text"
              autocomplete="off"
              @focus="onFocus"
              v-model="state.keyword"
              v-focus
            />
            <div
              v-if="state.displayBoardSuggestion"
              class="flex overflow-y-scroll rounded"
            >
              <ul class="z-40 text-sm h-32 w-44">
                <li
                  v-for="board in allBoardsList"
                  :key="board"
                  class="pl-2 py-1 bg-white hover:bg-gray-100 hover:cursor-pointer"
                  @click="selectedBoardfromSuggestion(board)"
                >
                  <div class="flex flex-row">
                    <hash-tag-icon
                      v-if="board.visibility == 'public'"
                      class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600 text-base"
                    />
                    <private-icon
                      v-else
                      class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600 text-base"
                    />
                    <div class="w-full">{{ board.name }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <button
            type="button"
            class="text-xs px-1 rounded border border-gray-300 hover:bg-blue-500 hover:text-white disabled:bg-gray-300 disabled:text-white"
            :class="[state.keyword ? 'hover:border-white' : 'hover:cursor-auto']"
            @click="addPinToBoard(file.packageInfo)"
            :disabled="!state.keyword"
          >
            追加
          </button>
        </div>
        <div
          v-else
          class="flex overflow-x-scroll"
        >
          <div
            v-for="board in file.boards"
            :key="board.id"
            class="flex-none mr-0.5 text-xs p-0.5 rounded-lg truncate transition"
            style="max-width: 170px"
            @mouseover="hoverBoard(file.packageInfo, board, 'hover')"
            @mouseleave="hoverBoard(file.packageInfo, board, 'leave')"
          >
            <div class="flex flex-row">
              <div
                v-if="board.visibility === 'public'"
                class="flex flex-row"
              >
                <button
                  v-if="state.displayDeleteIcon
                    && state.targetFile.id == file.packageInfo.id
                    && state.targetBoard.id == board.id
                  "
                  type="button"
                  @click="togglePinModal(file.packageInfo, board)"
                >
                  <cancel-icon class="mr-0.5 font-bold" />
                </button>
                <hash-tag-icon
                  v-else
                  class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600"
                />
                <router-link
                  :to="{ path: `/boards/${board.id}` }"
                  class="hover:underline"
                  @mouseover="hoverBoard(file.packageInfo, board, 'hover')"
                  @mouseleave="hoverBoard(file.packageInfo, board, 'leave')"
                >
                  <span class="hover:underline">{{ board.name }}</span>
                </router-link>
              </div>
              <div
                v-if="board.visibility === 'limited' &&
                    [
                      ...board.owners,
                      ...board.members
                    ].includes(state.user.uid)
                "
                class="flex flex-row"
              >
                <button
                  v-if="state.displayDeleteIcon
                    && state.targetFile.id == file.packageInfo.id
                    && state.targetBoard.id == board.id
                  "
                  type="button"
                  @click="togglePinModal(file.packageInfo, board)"
                  @mouseover="hoverBoard(file.packageInfo, board, 'hover')"
                  @mouseleave="hoverBoard(file.packageInfo, board, 'leave')"
                >
                  <cancel-icon class="mr-0.5 font-bold" />
                </button>
                <private-icon
                  v-else
                  class="flex justify-center items-center mt-0.5 mr-0.5 text-yellow-600"
                />
                <router-link
                  :to="{ path: `/boards/${board.id}` }"
                  class="flex flex-row hover:underline"
                  @mouseover="hoverBoard(file.packageInfo, board, 'hover')"
                  @mouseleave="hoverBoard(file.packageInfo, board, 'leave')"
                >
                  <div>{{ board.name }}</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <operation-add-pin-modal
    :show-pin-modal="state.addPinModalFlag"
    :close-pin-modal="toggleOperationModal"
    :file="state.selectedPin"
    @update-boards="updateBoard"
  />
  <operation-delete-pin-modal
    v-if="state.modalVisibleFlag"
    :modal-visible-flag="state.modalVisibleFlag"
    :toggle-pin-modal="togglePinModal"
    :selected-pin="state.selectedPin"
    :board-info="state.targetBoard"
    @remove-pin="removePinFromDeletePinModal"
    @close-modal="closeDeletePinModal"
    class="fadecenter"
  />
  <loading
    v-model:active="state.loading"
    color="#818589"
    :is-full-page="false"
  />
</template>

<script>
import firebase from "firebase/app";
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import utility from "./composables/utility";
import useBoardRepository from "./composables/useBoardRepository";
import { RepositoryFactoryForGae } from "@/api/gae/RepositoryFactory";
const FileRepositoryForGae = RepositoryFactoryForGae.get("file");
const BoardRepositoryForGae = RepositoryFactoryForGae.get("board");

import TooltipText from "./utilities/TooltipContent.vue";
import OperationAddPinModal from "./modals/PinModalAdd.vue";
import OperationDeletePinModal from "./modals/PinDeleteModal.vue";
import Plus from "../icons/Plus.vue";
import RoundEdit from "../icons/RoundEdit.vue";
import EyeIcon from "../icons/EyeIcon.vue";
import LockIcon from "../icons/LockIcon.vue";
import UnlikeIcon from "../icons/UnlikeIcon.vue";
import LikedIcon from "../icons/LikedIcon.vue";
import LikeCountIcon from "../icons/LikeCountIcon.vue";
import CancelAddPinIcon from "../icons/CancelIcon.vue";
import CancelIcon from "../icons/CancelBaselineIcon.vue";
import PrivateIcon from "../icons/PrivateIcon.vue";
import HashTagIcon from "../icons/HashTagIcon.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    TooltipText,
    OperationAddPinModal,
    OperationDeletePinModal,
    Plus,
    RoundEdit,
    EyeIcon,
    LockIcon,
    UnlikeIcon,
    LikedIcon,
    LikeCountIcon,
    CancelAddPinIcon,
    CancelIcon,
    PrivateIcon,
    HashTagIcon,
    Loading
  },

  emits: ["openPreviewModal", "togglePinModal"],

  props: {
    files: {
      type: Array,
      required: true
    },
    isNotBoardViwer: {
      type: Boolean,
      required: true
    },
    sortable: {
      type: Boolean,
      required: false,
      defalut: false
    }
  },

  async setup(props) {
    const router = useRouter();
    const user = firebase.auth().currentUser;
    const state = reactive({
      baseUrl: location.origin + "/img/",
      loading: false,
      displayDeleteIcon: false,
      modalVisibleFlag: false,
      addPinFlag: false,
      addPinModalFlag: false,
      displayBoardSuggestion: false,
      keyword: "",
      targetFile: {},
      targetBoard: {},
      selectedPin: {},
      addPinTargetBoard: {},
      hoverFlag: false,
      hoverIndex: null,
      user: user
    });
    const store = useStore();
    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });

    const boards = computed(() => store.state.board.myBoard);
    const favBoard = computed(() =>
      store.getters["board/defaultBoards"].find(x => x.name === "お気に入り")
    );
    const allBoardsList = computed(() => {
      let mergedBoards = Array.from(
        new Set(
          [...store.state.board.myPrivateBoard, ...store.state.board.publicBoard]
        )
      );
      if (state.keyword) {
        mergedBoards = mergedBoards.filter((board) => {
          return board.name.includes(state.keyword);
        });
      }
      return mergedBoards;
    });

    const onMouseOver = index => {
      state.hoverFlag = true;
      state.hoverIndex = index;
    };

    const hasAddPinPermission = file => {
      if (file.permission === "limited") {
        return file.created_by === user.uid;
      } else {
        return true;
      }
    };

    const hoverBoard = (file, board, type) => {
      if (type == "hover") state.displayDeleteIcon = true;
      else state.displayDeleteIcon = false;
      state.targetFile = file;
      state.targetBoard = board;
    };

    const { getMyBoard, getMyBoardToSideBar, getPublicBoard } = useBoardRepository();
    const updateBoard = async () => {
      await getMyBoard({ workSpace: userInfo.value.workSpace, userId: user.uid });
      await getPublicBoard({ workSpace: userInfo.value.workSpace });
      await getMyBoardToSideBar({ workSpace: userInfo.value.workSpace, userId: user.uid });
    };

    const fileType = router.currentRoute.value.params.type;
    const addPin = async file => {
      state.loading = true;
      const boardId = state.addPinTargetBoard.id;
      const pin_id = fileType == "slides" ? file.slideId : file.packageId;
      const pin_type = fileType == "slides" ? "slide" : "package";
      const payload = { pins: [{ pin_id, pin_type }], board_id: boardId };
      await BoardRepositoryForGae.addPin(payload);
      await updateBoard();
      state.loading = false;
      state.addPinFlag = false;
      state.keyword = "";
    };
    const removePin = async (file) => {
      const boardId = favBoard.value.id;
      const pinId = fileType == "slides" ? file.slideId : file.packageId;
      const pinType = fileType == "slides" ? "slide" : "package";
      const payload = { board_id: boardId, pins: [{ pin_id: pinId, pin_type: pinType }] };
      await BoardRepositoryForGae.removePin(payload);
      await updateBoard();
    };
    const createBoard = async values => {
      state.loading = true;
      const description = values.description ? values.description : ""
      const users = [];
      const payload = {
        boards: [
          {
            name: values.boardName,
            description: description,
            visibility: values.visibility,
            emoji: values.emoji,
            coverURL: values.coverURL,
            users: users
          }
        ],
        url_domain: location.origin
      };
      const result = await BoardRepositoryForGae.create(payload).json();

      state.loading = false;
      return result;
    };

    const addPinToBoard = async (file) => {
      const mergedBoards = Array.from(
        new Set(
          [...store.state.board.myPrivateBoard, ...store.state.board.publicBoard]
        )
      );
      const existedBoard = mergedBoards.filter((board) => board.name == state.keyword);
      if (existedBoard.length > 0) return await addPin(file);
      else {
        const createBoardValue = {
          boardName: state.keyword,
          description: "",
          visibility: "public",
          emoji: "",
          coverURL: "",
        };
        const result = await createBoard(createBoardValue);
        if (result.status === "200") {
          state.addPinTargetBoard.id = result.id;
          return await addPin(file);
        }
      }
    };

    const onMouseLeave = () => {
      state.hoverFlag = false;
    };

    const { formatTime, onDragStart, onDragEnd } = utility();

    const onDragStartFromBoard = (evt, file) => {
      const params = {
        fileType: file.pin_type,
        fileId: file.pin_id,
        permission: file.packageInfo.permission,
        createdBy: file.packageInfo.created_by,
        headerId: "slideInfo" in file ? file.pin_id : file.packageInfo.headerId,
        sortable: props.sortable
      };
      onDragStart(evt, params);
    };

    const isLikedPackage = file => {
      const likeList = userInfo.value.like_packages_list;
      return likeList.some(like => like === file.id);
    };
    const likePackage = async (file) => {
      const payload = {
        target: file.packageId,
        event_type: "like",
        url: {
          domain: location.origin,
          endpoint: `/package/${file.packageId}`
        }
      };
      store.state.user.userInfo.like_packages_list.push(file.packageId);
      file.liked_users.push(store.state.user.userInfo.username);
      await FileRepositoryForGae.likePackage(payload);
      await store.dispatch("user/setUser");
    };
    const unlikePackage = async (file) => {
      const payload = { target: file.packageId };
      store.state.user.userInfo.like_packages_list.filter(x => x != file.packageId);
      file.liked_users = file.liked_users.filter(x => x != store.state.user.userInfo.username);
      await FileRepositoryForGae.unlikePackage(payload);
      await store.dispatch("user/setUser");
    };

    const onFocus = () => {
      state.displayBoardSuggestion = true;
    };

    const onBlur = () => {
      state.displayBoardSuggestion = false;
    };

    const selectedBoardfromSuggestion = (target) => {
      state.addPinTargetBoard = target;
      state.keyword = target.name;
      state.displayBoardSuggestion = false;
    };

    const togglePinModal = (pin, board) => {
      state.selectedPin = pin;
      state.modalVisibleFlag = !state.modalVisibleFlag;
      state.targetBoard = board;
    };

    const toggleOperationModal = (file = {}) => {
      state.selectedPin = file;
      state.addPinFlag = !state.addPinFlag;
      if (!state.addPinFlag) state.keyword = "";
    };

    const removePinFromDeletePinModal = async () => {
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    const closeDeletePinModal = async () => {
      state.modalVisibleFlag = !state.modalVisibleFlag;
    };

    return {
      state,
      boards,
      allBoardsList,
      hasAddPinPermission,
      hoverBoard,
      addPinToBoard,
      updateBoard,
      removePin,
      onMouseOver,
      onMouseLeave,
      formatTime,
      onDragStartFromBoard,
      onDragEnd,
      isLikedPackage,
      likePackage,
      unlikePackage,
      onFocus,
      onBlur,
      selectedBoardfromSuggestion,
      togglePinModal,
      toggleOperationModal,
      removePinFromDeletePinModal,
      closeDeletePinModal
    };
  }
};
</script>

<style lang="postcss" scoped>
.sort {
  @apply border border-gray-300 border-dashed;
}
</style>